/*
---
name: Hero slider
category:
  - components
  - components/hero-slider
tag:
  - js
compile: true
fullDisplay: true
---

#### Hero slider classic
You can use data-slick attr to setup your slider and class "slick-slider" to init it.

!![](./components/hero-slider/hs-classic.html)

*/
$dots-size: rem(10);
$dots-w: rem(20);
$dots-h: rem(5);
$dots-color: $black;

@mixin component-hero-slider {
	/*! Slider */
	.slick-dots {
		li {
			button {
				&:before {
					font-size: $dots-size;
					top: rem(10);
					content: '';
					width: $dots-w;
					height: $dots-h;
					background: $dots-color;
				}
			}
		}
	}
	.slick-arrow {
		width: rem(40);
		height: rem(40);
		&:before {
			width: 100%;
			height: 100%;
			content: '';
			display: block;
		}
	}
	.slick-prev {
		&:before {
			background: url(../img/back.svg) no-repeat;
		}
	}
	.slick-next {
		&:before {
			background: url(../img/next.svg) no-repeat;
		}
	}
	.slider--wide {
		.slick-prev {
			left: rem(15);
		}
		.slick-next {
			right: rem(15);
		}
	}
	.slick--arrowout {
		.slick-prev {
			left: rem(-30);
		}
		.slick-next {
			right: rem(-30);
		}
	}
	.slick--high {
		.slick-dots {
			bottom: 25px;
		}
	}
}