@mixin component-aside {
    .aside {
        .nav-link {
            color: $dark;

            &:hover {
                color: $primary;
                opacity: 1;
            }
        }

        &.bg-dark {
            .nav-link {
                color: $white;
                opacity: 0.5;
            }
        }
    }
}