/*
---
name: Pricing
category:
  - components
  - components/pricing
tag:
  - base

compile: true
fullDisplay: true
---

#### Pricing vertical columns
!![](./components/pricing/price-1.html)

#### Pricing horizontal columns
!![](./components/pricing/price-2.html)

#### Pricing with background color
!![](./components/pricing/price-3.html)

*/
@mixin component-pricing {
}