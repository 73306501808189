$font-primary: 'Open Sans', sans-serif;
$heading-font: 'Arial';

@mixin base-fonts {
	/* Fonts */
	@font-face {
		font-family: 'icomoon';
		src:url('../fonts/icomoon/icomoon.eot?srf3rx');
		src:url('../fonts/icomoon/icomoon.eot?srf3rx#iefix') format('embedded-opentype'),
			url('../fonts/icomoon/icomoon.ttf?srf3rx') format('truetype'),
			url('../fonts/icomoon/icomoon.woff?srf3rx') format('woff'),
			url('../fonts/icomoon/icomoon.svg?srf3rx#icomoon') format('svg');
		font-weight: normal;
		font-style: normal;
	}
}