/*
---
name: Jumbotron
category:
  - components
  - components/jumbotron
tag:
  - base

compile: true
fullDisplay: true
---

#### Jumbotron
!![](./components/jumbotron.html)

*/
@mixin component-jumbotron {
}