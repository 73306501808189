/*!
Title: Main CSS File
Theme Name: Charity
Author Name: FairyTheme
Author URI: http://themeforest.net/user/fairytheme

Table of Contents:
------------------
1. Base
2. Components
	2.1. Cards
	2.2. Hero banner
	2.3. Slider
	2.4. List
	2.5. Navbar
	2.6. Service
	2.7. Social icons
	2.8. Title
3. Modules
----------------------------------*/

//Base
@import "base/base";
//Bootstrap
@import "vendor/bootstrap/bootstrap.scss";
//Utilities
@import "utilities/utilities";
//Modules
@import "modules/modules";
// Components
@import "components/components";

/*! 1. Base */
@include base;
/*! 2. Components */
@include components;
/*! 3. Modules */
@include modules;