/*
---
name: Card
category:
  - components
  - components/card
tag:
  - base

compile: true
fullDisplay: true
---

#### Card
!![](./components/card/card-classic.html)

#### Card for Case
!![](./components/card/card-case.html)

#### Card with image
!![](./components/card/card-with-img.html)

#### Card with right image
!![](./components/card/card-img-right.html)

#### Card with left image
!![](./components/card/card-img-left.html)

#### Card with overlay
!![](./components/card/card-overlay.html)

#### Card with video
!![](./components/card/card-with-video.html)

#### Card text first
!![](./components/card/card-text-first.html)

#### Card with slider
!![](./components/card/card-with-slider.html)

#### Card full
!![](./components/card/card-full.html)
*/
@mixin component-card {
	/*! Cards */
	.card {
		border-radius: 0;
		background: none;

		&-title {
			a {
				text-decoration: none;
			}
		}

		&-img {
			border-radius: 0;

			&-250 {
				max-height: rem(250);
				overflow:hidden;
			}
		}

		&__image {
			position: relative;
		}

		&--special {
			.card-img {
				position: relative;
				overflow: hidden;

				@include breakpoint(small-down) {
					max-height: rem(400);
				}

				@include breakpoint(small) {
					img {
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						height: 100%;
						width: 100%;
						object-fit: cover;
					}
				}
			}
		}

		&-gallery {
			.overlay {
				width: 100%;
				height: 100%;
				opacity: 0;
				background-color: rgba($black,.5);
				-webkit-transition: all .4s ease-in-out;
				transition: all .4s ease-in-out;
				position: absolute;
				top: 0;
				left: 0;
			}

			.card {
				&:hover {
					.overlay {
						opacity: 1;
					}
				}
			}
		}

		&-columns {
			@include breakpoint(xsmall) {
				column-count: 3;
				column-gap: 1.25rem;
			}

			.card {
				display: inline-block;
				width: 100%;
				margin-bottom: 0.75rem;
			}
		}
	}

	.img-hover {
		transition: all 0.5s ease;

		&:hover {
			transform: rotate(3deg) scale(1.1);
		}
	}

	.c-video-constructor {
		@include absolute-center;

		display: inline-block;
		vertical-align: top;
		width: 4.375rem;
		height: 4.375rem;
		background-color: #9c9ea0;
		padding: 0;
		border: 2px solid transparent;
		border-radius: 50%;
		opacity: 1;

		&:hover {
			cursor: pointer;
			background-color: #4b4c4d;
			border: 1px solid transparent;
		}

		&__arrow {
			@include absolute-center;
			content: '';
			display: block;
			width: 0;
			height: 0;
			border: inset 0.4375rem;
			border-color: transparent transparent transparent #fff;
			border-left-style: solid;
			border-right-width: 0;
		}
	}

	.c-card__overlay {
		position: relative;

		.c-card__body {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			padding: rem(20);
		}
	}
}