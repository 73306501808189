$base-font-size: 14px;

@mixin base-body {
	/* Body */
	html {
		font-size: $base-font-size;
	}
	body {
		font-family: $font-primary;
		font-weight: 400;
		line-height: 1.7;
		&.offcanvas {
			overflow: hidden;
		}
	}
}