/// Line height for items in a list.
/// @type Number
$list-lineheight: $p-lineheight !default;

/// Bottom margin for items in a list.
/// @type Number
$list-margin-bottom: 0 !default;

/// Bullet type to use for unordered lists (e.g., `square`, `circle`, `disc`).
/// @type String
$list-style-type: none !default;

/// Positioning for bullets on unordered list items.
/// @type String
$list-style-position: outside !default;

/// Left (or right) margin for lists.
/// @type Number
$list-side-margin: 0rem !default;

/// Left (or right) margin for a list inside a list.
/// @type Number
$list-nested-side-margin: 1.25rem !default;

@mixin base-lists {
	/* List */
	// Lists
	ul,
	ol,
	dl {
		line-height: $list-lineheight;
		margin-bottom: $list-margin-bottom;
		list-style-position: $list-style-position;
		padding: 0;
	}

	// List items
	li {
		/* stylelint-disable-next-line sh-waqar/declaration-use-variable */
		font-size: inherit;
	}

	// Unordered lists
	ul {
		list-style-type: $list-style-type;
		margin-left: $list-side-margin;
	}

	// Ordered lists
	ol {
		margin-left: $list-side-margin;
	}

	// Nested unordered/ordered lists
	ul,
	ol {
		& & {
			margin-left: $list-nested-side-margin;
			margin-bottom: 0;
		}
	}
}
