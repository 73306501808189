@mixin base-common {
	img {
		max-width: 100%;
		height: auto;
		-ms-interpolation-mode: bicubic;
		display: inline-block;
		vertical-align: middle;
	}
	#map {
		width: 100%;
		height: 400px;
	}
	.mw-64 {
		max-width: 64px;
	}
	.mh-300 {
		max-height: 300px;
		overflow: hidden;
	}
}