// $primary: #ffe012;
$primary: #FFD148;

$nav-link-color: rgba(255,255,255,.5);
$navbar-light-active-color: #000;
$navbar-dark-active-color: #fff;

$dark: #343a40;

$pagination-color: #000000;
